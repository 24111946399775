[class^="ico-language"] {
  background: url("../../../../assets/images/ico-languages.png") 0 0 no-repeat;
  display: inline-block;
  width: 16px;
  height: 12px; }

.lang-list {
  position: relative;
  margin: auto 0 20px;
  color: #fff;
  z-index: 1; }
  .lang-list__lang {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 19px; }
    .lang-list__lang::after {
      display: none !important; }
  .lang-list__menu {
    min-width: auto !important; }
    .lang-list__menu .dropdown-item {
      display: flex;
      align-items: center;
      gap: 4px; }

.ico-language--ar {
  background-position: 0 0px; }

.ico-language--en {
  background-position: 0 -12px; }

.ico-language--de {
  background-position: 0 -24px; }

.ico-language--fr {
  background-position: 0 -36px; }

.ico-language--pt {
  background-position: 0 -48px; }

.ico-language--ru {
  background-position: 0 -60px; }

.ico-language--es {
  background-position: 0 -72px; }

.ico-language--uk {
  background-position: 0 -84px; }

.ico-language--zh {
  background-position: 0 -96px; }

.ico-language--bg {
  background-position: 0 -108px; }

.ico-language--tr {
  background-position: 0 -120px; }

.ico-language--ja {
  background-position: 0 -132px; }

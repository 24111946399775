:root {
  @each $name, $color in $main-color {
    --#{$name}: #{$color};
  }
  --main-body-font-size: #{$font-size-base};
}

.text-black {
  color: black;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.border-bottom-dotted {
  border-width: $border-width !important;
  border-bottom: 1px dashed;
  border-radius: 0;
}

.border-bottom-solid {
  border-width: $border-width !important;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

:focus {
  outline: none !important;
}

.bg-super-light {
  background: #fafbfc;
}

.modal-content {
  overflow: hidden;
}

.custom-disabled {
  &:hover {
    pointer-events: none !important;
  }
}

// =============================================================================
// Opacity Helper
// =============================================================================
@for $i from 1 through 9 {
  .opacity-0-#{$i} {
    opacity: #{$i/10};
  }
}

// =============================================================================
// Font Size Helper
// =============================================================================

@for $i from 1 through 40 {
  .fs-#{$i} {
    font-size: rem(1px * $i) !important;
  }
}

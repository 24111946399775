$font-family-sans-serif: "PT Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: "PT Sans", SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;

// Base color
$main-color: (
  main-link: #164d91,
  main-link-hover: #164d91,
  main-text-body: #565656
);

// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #ffa037 !default;
$yellow: #ffc107 !default;
$green: #00923f !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$success: $green;
$danger: #f55f5f;
$warning: $orange;
$white: white;
$black: black;
$dark: #222222;
$info: #164d91;
$light: #f9f9f9;
$secondary: #999999;
$primary: #3182fc;
$muted: #d1c0c0;
$info: #166aca;

$linkcolor: map-get($main-color, main-link);
$linkcolorhov: map-get($main-color, main-link-hover);

// Container
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1520px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 2,
  2: $spacer,
  3: $spacer * 2,
  4: $spacer * 3,
  5: $spacer * 5.5
);
$grid-gutter-width: 1.95rem;

$modal-sm: 300px !default;
$modal-md: 536px;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;

$default-fontSize: 16px;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1 !default;
$body-color: map-get($main-color, main-text-body);

// Modal
$modal-backdrop-bg: $white;
$modal-backdrop-opacity: 0.5 !default;

// Headings
$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 3;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.125;
// $headings-color: $black;
$headings-font-weight: 400;

// Radius
$border-radius: 0.25rem;
$border-width: 1px;

$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.625rem;

// HR
$hr-color: map-get($main-color, main-light-gray);

// Btn & Input
$input-btn-padding-x: 0.75rem;
$input-btn-padding-y: 0.875rem;
$input-btn-padding-x-lg: 1.85rem;
$input-btn-padding-y-lg: 1.04rem;

$input-btn-font-size-lg: $font-size-lg;

$btn-font-size: $font-size-base;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-padding-x: 1.83rem;
$btn-padding-y: 0.563rem;
$btn-padding-x-lg: 1.9rem;
$btn-padding-y-lg: 1.0625rem;

$input-font-size: 0.875rem;

$input-padding-x: $input-btn-padding-x;
$input-padding-y: 0.657rem;
$input-padding-x-lg: $input-btn-padding-x;
$input-padding-y-lg: 1.06rem;

$input-btn-font-size-lg: $btn-font-size;
$input-border-color: $gray-400;
$input-placeholder-color: map-get($main-color, main-text-body);
$input-color: map-get($main-color, main-text-body);
$input-height-lg: 2.63rem;

// Checkbox
$form-check-input-checked-color: map-get($main-color, main-text-body);
$form-check-input-checked-bg-color: white;
$form-check-input-checked-border-color: map-get($main-color, main-light-gray);

// Paragraph
$paragraph-margin-bottom: 0;

// Link
$link-color: map-get($main-color, main-link);
$link-decoration: none;
$link-hover-color: map-get($main-color, main-link-hover);
$link-hover-decoration: none;

%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin respond-to($media) {
  @if $media == huge {
    @media screen and (max-width: 1330px) {
      @content;
    }
  } @else if $media == xlarge {
    @media screen and (max-width: 1200px) {
      @content;
    }
  } @else if $media == large {
    @media screen and (max-width: 992px) {
      @content;
    }
  } @else if $media == medium {
    @media screen and (max-width: 768px) {
      @content;
    }
  } @else if $media == small {
    @media screen and (max-width: 576px) {
      @content;
    }
  } @else {
    @media screen and (max-width: $media) {
      @content;
    }
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / $default-fontSize + 0rem;
}

@function em($size, $baseSize: $default-fontSize) {
  @return #{$size/$baseSize}em;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

[class^="ico-language"] {
  background: url("../../../../assets/images/ico-languages.png") 0 0 no-repeat;
  display: inline-block;
  width: 16px;
  height: 12px;
}

.lang-list {
  position: relative;
  margin: auto 0 20px;
  color: #fff;
  z-index: 1;

  &__lang {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-left: 19px;

    &::after {
      display: none !important;
    }
  }

  &__menu {
    min-width: auto !important;

    .dropdown-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.ico-language {
  &--ar {
    background-position: 0 0px;
  }
  &--en {
    background-position: 0 -12px;
  }
  &--de {
    background-position: 0 -24px;
  }
  &--fr {
    background-position: 0 -36px;
  }
  &--pt {
    background-position: 0 -48px;
  }
  &--ru {
    background-position: 0 -60px;
  }
  &--es {
    background-position: 0 -72px;
  }
  &--uk {
    background-position: 0 -84px;
  }
  &--zh {
    background-position: 0 -96px;
  }
  &--bg {
    background-position: 0 -108px;
  }
  &--tr {
    background-position: 0 -120px;
  }
  &--ja {
    background-position: 0 -132px;
  }
}
